export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Create Order',
    route: 'create-order',
    icon: 'PlusSquareIcon',
  },
  {
    title: 'Orders',
    route: 'orders',
    icon: 'ListIcon',
  },
  {
    title: 'Customers',
    route: 'customers',
    icon: 'UsersIcon',
  },
  // {
  //   title: 'Store Setup',
  //   route: 'store-setup',
  //   icon: 'PackageIcon',
  // },
  // {
  //   title: 'System Settings',
  //   route: 'system-settings',
  //   icon: 'SettingsIcon',
  // },
]
